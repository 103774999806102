import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import classNames from "classnames";

import whitelogo from "../../assets/images/whitelogo.png";
import Instagram from "assets/images/instagram.png";
import Facebook from "../../assets/images/Facebook.png";
import Linkdin from "../../assets/images/Linkdin.png";
import Twitter from "../../assets/images/twitter.png";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import underline from "../../assets/images/underline-footer.png";
import { MdEmail } from "react-icons/md";
import { APP_NAME } from "constants/app.constant";
import axiosInstance from "apiServices/axiosInstance";

export default function Footer() {
  const [footerInfo, setFooterInfo] = useState(null)
  const [footerApiFlag, setFooterApiFlag] = useState(false)
  const footerDetails = useSelector((state) => state?.footer?.footer?.footerData)
  const selectedLanguage = useSelector((state) => state?.language?.language?.selectedLanguage);

  const fetchFooterData = async () => {
    try {
      const response = await axiosInstance.get(`client/configuration/one/contact_us`);
      // console.log("🚀 ~ fetchFooterData ~ response:", response)
      if (response?.status) {
        setFooterInfo(response?.data);
      }
    } catch (error) {
      // console.log(error?.message);
    }
  };
  useEffect(() => {
    setFooterApiFlag(true);
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (footerApiFlag) {
      fetchFooterData();
      setFooterApiFlag(false)
    }
  }, [footerApiFlag]);
  useEffect(() => {
    setFooterInfo(footerDetails);
  }, [footerDetails]);



  return (
    <footer>
      <div className="bg-[#003A7B]">
        <div className="container">
          <div className="py-8">
            <div className="lg:grid lg:grid-cols-6 flex flex-col justify-center items-center gap-10">
              {/* description */}
              <div className="lg:col-span-2">
                <div>
                  <div className="lg:justify-start  w-full flex justify-center">
                    <img src={whitelogo} alt="" />
                  </div>
                  <div className="text-[#fff] font-outfit text-base pt-6">
                    <p className="text-left">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a type
                      specimen book.
                    </p>
                  </div>
                </div>
              </div>
              {/* quick links */}
              <div className="lg:col-span-4 lg:flex-row lg:w-full w-full flex flex-col justify-center font-outfit capitalize">
                <div className="lg:item-center lg:w-full sm:flex-row sm:justify-around flex flex-col">
                  <div className="">
                    <ul className="lg:pt-0 pt-6 leading-5 text-center lg:text-left ">
                      <li className="font-medium text-white text-lg">
                      {selectedLanguage?.code === "EN" ?`Quick links`:`લિંક્સ`} 
                      </li>
                      <li className="lg:hidden flex justify-center">
                        <img src={underline} alt="" />
                      </li>
                    </ul>
                    <ul className="leading-5 md:pt-2 pt-0   text-center font-normal text-base lg:text-left">
                      <li className="text-white pb-2 pt-2">
                        <Link to="/" className="">
                        {selectedLanguage?.code === "EN" ?`Home`:`હોમ`}  
                        </Link>
                      </li>
                      <li className="text-white pb-2">
                        <Link to="/about-us" className="">
                        {selectedLanguage?.code === "EN" ?`About us`:`અમારા વિશે`}   
                        </Link>
                      </li>
                      <li className="text-white pb-2">
                        <Link to="/our-services" className="">
                        {selectedLanguage?.code === "EN" ?`Services`:`સેવાઓ`}    
                        </Link>
                      </li>
                      <li className="text-white pb-2">
                        <Link to="/blogs" className="">
                        {selectedLanguage?.code === "EN" ?`Blog`:`બ્લોગ્સ`}    
                        </Link>
                      </li>
                      <li className="text-white pb-2">
                        <Link to="/contact-us" className="">
                        {selectedLanguage?.code === "EN" ?` Contact us`:`સંપર્ક`}  
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="">
                    <ul className="lg:pt-0 pt-6 leading-5 text-center lg:text-left">
                      <li className="font-medium text-white text-lg">
                      {selectedLanguage?.code === "EN" ?`About us`:`અમારા વિશે`}
                        </li>
                      <li className="lg:hidden flex justify-center">
                        <img src={underline} alt="" />
                      </li>
                    </ul>
                    <ul className="leading-5 md:pt-2 pt-0 text-center font-normal text-base lg:text-left">
                      <li className="text-white pt-2 pb-2">
                        <Link to="/about-us/our-team" className="">
                        {selectedLanguage?.code === "EN" ?`Our team`:`અમારી ટીમ`}  
                        </Link>
                      </li>

                      {/* <li className="text-white pb-2">
                        <Link to="/sample-report" className="">
                          Sample reports
                        </Link>
                      </li> */}

                      <li className="text-white pb-2">
                        <Link to="/about-us/our-technology" className="">
                        {selectedLanguage?.code === "EN" ?`Our technology`:`અમારી ટેકનોલોજી`} 
                        </Link>
                      </li>
                      <li className="text-white pb-2">
                        <Link to="/privacy-Policy" className="">
                        {selectedLanguage?.code === "EN" ?`Privacy policy`:`ગોપનીયતા નીતિ`} 
                        </Link>
                      </li>
                      <li className="text-white pb-2">
                        <Link to="/terms-And-Condition" className="">
                        {selectedLanguage?.code === "EN" ?`Terms & Conditions`:`નિયમો અને શરતો`} 
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="lg:item-center lg:w-full sm:flex-row sm:justify-around flex flex-col">
                  <div className="">
                    <ul className="lg:pt-0 pt-6 leading-5 text-center lg:text-left">
                      <li className="font-medium text-white text-lg">
                      {selectedLanguage?.code === "EN" ?`Services`:`સેવાઓ`} 
                        </li>
                      <li className="lg:hidden flex justify-center pb-2">
                        <img src={underline} alt="" />
                      </li>
                    </ul>
                    <ul className="leading-5 md:pt-2 pt-0 text-center font-normal text-base lg:text-left">
                      {footerInfo && footerInfo?.services[0]?.result?.map((service) => {
                        return (
                          <>
                            <li className="text-white pb-2" key={service?.value}>
                              <Link to={`/our-services/${service?.slug}`} className="capitalize">{selectedLanguage?.code === "EN" ? service?.label : service?.guj_service_name}</Link>
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  </div>
                  <div className="">
                    <ul className="lg:pt-0 pt-6 leading-5 text-center lg:text-left">
                      <li className="font-medium text-white text-lg">
                      {selectedLanguage?.code === "EN" ?`Contact us`:`સંપર્ક માહિતી`}  
                        </li>
                      <li className="lg:hidden flex justify-center pb-2">
                        <img src={underline} alt="" />
                      </li>
                    </ul>
                    <ul className="leading-5 md:pt-2 pt-0 text-center font-normal text-base lg:text-left ">
                      <li className="text-white flex lg:justify-start gap-3 justify-center items-center pb-2">
                        <div className="flex gap-3">
                          {/* <IoLocationOutline /> */}
                          <span>
                            {selectedLanguage?.code === "EN" ? (footerInfo?.contact_us?.address)
                              : (footerInfo?.translation?.address)}
                          </span>
                        </div>
                      </li>
                      <li className="text-white flex md:justify-start gap-3 justify-center items-center pb-2">
                        <a className="flex items-center gap-3" href={`tel:${footerInfo?.contact_us?.phone_no}`}>
                          <IoCallOutline />
                          <span className="">{footerInfo?.contact_us?.phone_no}</span>
                        </a>
                      </li>
                      <li className="text-white flex md:justify-start gap-3 justify-center items-center pb-2">
                        <a className="flex items-center gap-3" href={`mailto:${footerInfo?.contact_us?.email}`}>
                          <MdEmail />
                          <span className="">{footerInfo?.contact_us?.email}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* social media links  */}
            <div className=" lg:justify-start flex justify-center gap-4 mt-2 pt-6">
              <Link to={`${footerInfo?.social_media[0]?.instagram}`} target="_blank">
                <img className="cursor-pointer mr-2" src={Instagram} alt="Instagram" />
              </Link>
              <Link to={`${footerInfo?.social_media[0]?.facebook}`} target="_blank">
                <img className="cursor-pointer mr-2" src={Facebook} alt="Facebook" />
              </Link>
              <Link to={`${footerInfo?.social_media[0]?.linkedIn}`} target="_blank">
                <img className="cursor-pointer mr-2" src={Linkdin} alt="LinkdIn" />
              </Link>
              <Link to={`${footerInfo?.social_media[0]?.twitter}`} target="_blank">
                <img className="cursor-pointer mr-2" src={Twitter} alt="Twitter" />
              </Link>
            </div>
            {/* Copyright block  */}
            <div className="flex justify-center gap-4 mt-2 pt-6">
              <p className="text-[#ffffff] text-center text-sm text-bold mt-5">
                Copyright &copy; {`${new Date().getFullYear()}`}{" "}
                <span className="font-semibold">{`${APP_NAME}`}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
