import { EnglandSvg, IndiaIconSvg } from "assets/svg";


export const webNavigationLinks = [
    { value: "home", label: "Home",gujLabel:"હોમ", path: "/" },
    { value: "services", label: "Our Services",gujLabel:"સેવાઓ", path: "/our-services" },
    { value: "about", label: "About Us",gujLabel:"અમારા વિશે", path: "/about-us" },
    { value: "blogs", label: "Blogs",gujLabel:"બ્લોગ્સ", path: "/blogs" },
    { value: "contact", label: "Contact Us",gujLabel:"સંપર્ક", path: "/contact-us" },
];


export const languageOptions = {
    languages: [
        { code: "EN", name: "English",gujName:"અંગ્રેજી", icon: <EnglandSvg width={20} /> },
        { code: "GU", name: "Gujarati",gujName:"ગુજરાતી", icon: <IndiaIconSvg width={20} /> },
    ]
}