import React, { useCallback, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay, Navigation } from "swiper/modules";

import TestimonialCard from "./testimonialCard";

const TestimonialSlider = ({ breakpoints, testimonials }) => {

  // const { breakpoints, testimonials } = props;
  // console.log("🚀 ~ TestimonialSlider ~ breakpoints:", breakpoints)
  // console.log("🚀 ~ TestimonialSlider ~ testimonials123:", testimonials)

  const minHeightRef = useRef(0);
  const calculateMinHeight = useCallback(() => {
    let minHeight = 0;
    testimonials?.forEach((item, index) => {
      let id = `testimonial-${index}`;
      let element = document.getElementById(id);
      // console.log("🚀 ~ testimonials?.forEach ~ element:", element)
      if (element) {
        const rect = element.offsetHeight
        // console.log("🚀 ~ testimonials?.forEach ~ rect:", rect)
        if (rect > minHeight) {
          minHeight = rect
        }
      }
    });
    // console.log("Return Height----", minHeight)
    return minHeight;
  }, [testimonials]);

  useEffect(() => {
    requestAnimationFrame(() => {
      setTimeout(() => {
        minHeightRef.current = calculateMinHeight();
        testimonials?.forEach((item, index) => {
          let id = `testimonial-${index}`;
          let element = document.getElementById(id);
          // console.log("🚀 ~ testimonials?.forEach ~ element:", element)
          if (element) {
            element.style.height = `${minHeightRef.current}px`;
          }
        });

      }, 0);
    })


  }, [calculateMinHeight, testimonials]);

  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    autoplay:
    {
      delay: 1,
      disableOnInteraction: false,
    },
    speed: 9000,
    loop: true,
    navigation: false,
    modules: [Autoplay, Navigation],
    breakpoints: { ...breakpoints },
  };

  return (
    <div>
      <Swiper
        {...swiperParams}
      >
        {testimonials?.map((testimonial, index) => {
          return (
            <SwiperSlide
              className="bg-[#F5FAFF] rounded-lg  shadow-md "
              key={index}
            >
              <TestimonialCard
                id={`testimonial-${index}`} // Apply ID here
                testimonial={testimonial}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default TestimonialSlider;